import {
  IDataRes,
  ICategory,
  ICategoryParam,
  IDataResList,
  ICategoryDetail,
  IDataResCategoryDetail,
  IParamCategoryDetail,
  IParamListPost,
} from '@interfaces/Category';
import httpRequest from './Api';

const Category = {
  getAllCategory({ ...rest }: Partial<ICategoryParam>): Promise<IDataRes<ICategory>> {
    const url = '/list-categories';
    return httpRequest.get(url, { params: { ...rest } });
  },

  getAllList(): Promise<IDataResList<ICategory>> {
    const url = '/list-categories';
    return httpRequest.get(url);
  },

  getCategoryId({ ...rest }: Partial<IParamCategoryDetail>): Promise<IDataResCategoryDetail<ICategoryDetail>> {
    const url = '/detail-category';
    return httpRequest.get(url, { params: { ...rest } });
  },

  getPostId({ ...rest }: Partial<IParamCategoryDetail>): Promise<IDataResCategoryDetail<ICategoryDetail>> {
    const url = '/detail-post';
    return httpRequest.get(url, { params: { ...rest } });
  },

  getPostList({ ...rest }: Partial<IParamListPost>): Promise<IDataResCategoryDetail<ICategoryDetail>> {
    const url = '/list-posts';
    return httpRequest.get(url, { params: { ...rest } });
  },
};

export default Category;
