import { IDataPage, IPageParam, StatePage } from '@interfaces/gameDetail';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GameDetail from '@services/gameDetail';
const initialState: StatePage<IDataPage> = {
  data: {
    listGameDetail: null,
  },
  loading: false,
  error: '',
};

export const getAllGameDetail = createAsyncThunk(
  'GameDetail/getAllGameDetail',
  async (payload: Partial<IPageParam>, { getState }) => {
    try {
      const { data: dataRes } = await GameDetail.getDetailSlug({ ...payload });
      return {
        listGameDetail: { ...dataRes.data },
      };
    } catch (error) {
      console.log(error);
    }
  },
);

const gameDetailSlice = createSlice({
  name: 'GameDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('GameDetail/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('GameDetail/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('GameDetail/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default gameDetailSlice.reducer;
