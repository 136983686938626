import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import './style.scss';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { IUserLogin } from '@interfaces/UserInterface';
import Loading from '@components/common/loading';
import { authLogin, getCurrentUser } from '@store/authSlice';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { handleOpen } from '@store/commonSlice';
import { useNavigate } from 'react-router-dom';
type IModalBasic = {
  children: React.ReactNode;
};
export default function ModalBasicMobile({ children }: IModalBasic) {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { data } = useAppSelector((state) => state.homeSlice);
  const { openLogin } = useAppSelector((state) => state.commonSlice);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (!openLogin) return;
    setIsModalOpen(openLogin);
  }, [openLogin]);
  const handleShow = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(handleOpen(false));
  };
  const handleSubmitLogin = (values: IUserLogin) => {
    try {
      dispatch(
        authLogin({
          data: values,
          callback() {
            notification.success({ message: 'Đăng nhập thành công!', duration: 3 });
            dispatch(
              getCurrentUser({
                callback() {
                  navigate('/');
                  setIsModalOpen(false);
                },
              }),
            );
          },
          error(err) {
            notification.error({ message: err, duration: 3 });
            setIsModalOpen(false);
          },
        }),
      );
    } catch (err) {
      const msg = (err as any).response;
      if (msg?.data.message !== undefined) {
        notification.error({ message: 'Đăng nhập thất bại', duration: 3 });
        setIsModalOpen(false);
      }
    }
  };

  return (
    <div className="modale-basic">
      <div className="open-btn" onClick={handleShow}>
        {children}
      </div>
      <Modal className="modal-login-mobile" closable={true} open={isModalOpen} onCancel={handleCancel} footer={null}>
        <div className="modal-login-mobile-container">
          <Loading sx="modal-loading-container" />
          <div className="logo">
            <img src={data.header.logo} alt="" />
          </div>

          <Form autoComplete="off" onFinish={handleSubmitLogin} size="large">
            <Form.Item
              label=""
              colon={false}
              name="username"
              rules={[{ required: true, message: 'Vui lòng nhập tên truy cập' }]}
            >
              <Input placeholder="Tên truy cập" prefix={<UserOutlined className="site-form-item-icon" />} />
            </Form.Item>
            <Form.Item
              className="password"
              label=""
              colon={false}
              name="password"
              rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
            >
              <Input.Password placeholder="mật khẩu" prefix={<LockOutlined className="site-form-item-icon" />} />
            </Form.Item>
            <a className="txt-blue2" style={{ color: 'red' }} href="/quen-mat-khau">
              Quên ID hoặc mật khẩu?
            </a>
            <div className="btnGroup" style={{ marginTop: 10 }}>
              <Button className="btn-check" htmlType="submit">
                Đăng nhập
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
