import Cookies from 'universal-cookie';

export function logout() {
  // serviceLogout()

  setTimeout(() => {
    const cookies = new Cookies();

    localStorage.clear();
    sessionStorage.clear();
    cookies.remove('userToken');
    cookies.remove('userData');
    location.reload();
  }, 100);
}

export const fomatWallet = (addressWallet: string) => {
  return (
    addressWallet.substring(0, 9) + '...' + addressWallet.substring(addressWallet.length - 9, addressWallet.length)
  );
};

export const toPlainString = (num: any) => {
  return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {
    return e < 0 ? b + '0.' + Array(1 - e - c.length).join('0') + c + d : b + c + d + Array(e - d.length + 1).join('0');
  });
};
