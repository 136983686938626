import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDataPage, IPageParam, StatePage } from '@interfaces/Detail';
import Detail from '@services/Detail';

const initialState: StatePage<IDataPage> = {
  data: {
    pageItem: null,
  },
  loading: false,
  error: '',
};

export const getPage = createAsyncThunk('Detail/getAllDetail', async (payload: Partial<IPageParam>, { getState }) => {
  try {
    const { data: dataRes } = await Detail.getDetailSlug({ ...payload });
    return {
      pageItem: { ...dataRes },
    };
  } catch (error) {
    console.log(error);
  }
});

const detailSlice = createSlice({
  name: 'Detail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Detail/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Detail/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Detail/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default detailSlice.reducer;
