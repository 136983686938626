import { IInvite } from '@interfaces/Invite';
import { convert } from '@utils/convert';

const HistoryInviteList = ({ data }: { data: IInvite[] }) => {
  return (
    <div className="table-mobile">
      {data.map((item, index) => (
        <div key={index} className="table-mobile-item">
          <div className="flex-column">
            <b>{item.member.username || ''}</b>
            <span style={{ color: '#555' }}>{convert.convertDateFormat(item.created_at)}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HistoryInviteList;
