import * as React from 'react';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getAllLevel } from '@store/levelSlice';
import Banner from './banner';
import InfoVip from './infoVip';
import TbaleLevel from './table';
import BannerMobile from './banner-mobile';
import TableMobile from './table-mobile';
export interface ILevelPageProps {}

export default function LevelPage(props: ILevelPageProps) {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.levelSlice);
  const { size } = useAppSelector((state) => state.commonSlice);
  React.useEffect(() => {
    dispatch(getAllLevel());
  }, []);
  return (
    <>
      {size > 900 ? (
        <div className="layout-pc">
          <Banner data={data.slide} />
          <InfoVip />
          <TbaleLevel data={data} />
        </div>
      ) : (
        <div className="layout-sp">
          {' '}
          <BannerMobile data={data.slide} level={data.vip} />
          <TableMobile data={data} />
        </div>
      )}
    </>
  );
}
