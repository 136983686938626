import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Navigation, Pagination } from 'swiper';
import './style.scss';
import { ILevel, ISlideLevel } from '@interfaces/Level';
import 'swiper/css/pagination';
export type IBannerMobile = {
  data: ISlideLevel[];
  level: ILevel[];
};

export default function BannerMobile(props: IBannerMobile) {
  const { data, level } = props;
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>`;
    },
  };

  return (
    <>
      <div className="layout-full level-banner">
        {data.length > 0 && (
          <Swiper
            pagination={pagination}
            loop={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <div style={{ width: '100%' }}>
                  <img style={{ width: '100%' }} src={item.url} alt="" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}{' '}
        <div className="medal">
          <div>
            <img src={level[0]?.image} alt="vip01" />
            {level[0]?.level_name}
          </div>
          <div>
            <img src={level[1]?.image} alt="vip01" />
            {level[1]?.level_name}
          </div>
          <div>
            <img src={level[2]?.image} alt="vip01" />
            {level[2]?.level_name}
          </div>
          <div>
            <img src={level[3]?.image} alt="vip01" />
            {level[3]?.level_name}
          </div>
          <div>
            <img src={level[4]?.image} alt="vip41" />
            {level[4]?.level_name}
          </div>
          <div>
            <img src={level[5]?.image} alt="vip01" />
            {level[5]?.level_name}
          </div>
          <div>
            <img src={level[6]?.image} alt="vip01" />
            {level[6]?.level_name}
          </div>
          <div>
            <img src={level[7]?.image} alt="vip01" />
            {level[7]?.level_name}
          </div>
          <div>
            <img src={level[8]?.image} alt="vip01" />
            {level[8]?.level_name}
          </div>
          <div>
            <img src={level[9]?.image} alt="vip01" />
            {level[9]?.level_name}
          </div>
          <div>
            <img src={level[10]?.image} alt="vip01" />
            {level[10]?.level_name}
          </div>
        </div>
      </div>
    </>
  );
}
