import { useAppDispatch, useAppSelector } from '@hook/index';
import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import HeaderComponent from './Header';
import './style.scss';
import { getCurrentUser } from '@store/authSlice';
import MenuHeader from './Menu';
import Footer from './Footer';
import { handleChangeTypeChat, handleResize } from '@store/commonSlice';
import MenuMobile from './MenuMobile';
import FooterMobile from './FooterMobile';
import Subfooter from './SubFooter';
import { getHeader } from '@store/homeSlice';
import Loading from '@components/common/loading';
import ScrollToTop from '@components/common/scroll';
import { getSetting } from '@store/settingSlice';
import Maintenance from '@components/maintenance';
import { EventHandlerPayload, LiveChatWidget } from '@livechat/widget-react';
type Props = {};

const DefaultLayout: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { size, typeChat } = useAppSelector((state) => state.commonSlice);
  const { data: dataSetting } = useAppSelector((state) => state.settingSlice);
  const { data: dataAuth } = useAppSelector((state) => state.authSlice);
  const { Header, Content } = Layout;

  const { pathname } = useLocation();
  useEffect(() => {
    if (location.pathname.split('/')[location.pathname.split('/').length - 1].includes('undefined')) {
      navigate('/notfound');
      return;
    }
    window.scrollTo(0, 0);
  }, [pathname]);
  const callback = () => {};
  useEffect(() => {
    if (dataAuth?.isLogin) dispatch(getCurrentUser({ callback }));
  }, []);
  useEffect(() => {
    dispatch(handleResize(window.innerWidth));
    const setSize = () => {
      dispatch(handleResize(window.innerWidth));
    };
    window.addEventListener('resize', setSize);
    return () => window.removeEventListener('resize', setSize);
  }, []);
  useEffect(() => {
    dispatch(getHeader());
    dispatch(getSetting());
  }, []);

  const handleHide = ({ visibility }) => {
    dispatch(handleChangeTypeChat(visibility));
  };

  if (!dataSetting.listSetting)
    return (
      <>
        {' '}
        <Loading loadingDefault={true} />
      </>
    );

  if (!Number(dataSetting.listSetting?.web_status)) {
    return (
      <>
        <Maintenance />
      </>
    );
  }
  return (
    <>
      <Loading />
      <Layout className="layout-default">
        <LiveChatWidget license="13227897" onVisibilityChanged={handleHide} visibility={typeChat} />
        <HeaderComponent />
        {size > 768 ? <MenuHeader /> : ''}
        <Content className="background-content">
          <Outlet />
        </Content>
        {size < 768 ? <Subfooter /> : ''}
        {size > 768 ? <Footer /> : <FooterMobile showPartner={true} />}
      </Layout>
      <ScrollToTop />
    </>
  );
};

export default DefaultLayout;
