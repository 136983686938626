import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Navigation, Pagination } from 'swiper';
import './style.scss';
import { ISlideLevel } from '@interfaces/Level';
import 'swiper/css/navigation';
export type IBanner = {
  data: ISlideLevel[];
};

export default function Banner(props: IBanner) {
  const { data } = props;
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>`;
    },
  };

  return (
    <>
      <div className="layout-full level-banner">
        <div className="layout-max">
          {data.length > 0 && (
            <Swiper
              loop={true}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Navigation, Autoplay]}
              className="mySwiper"
            >
              {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <div style={{ width: '100%' }}>
                    <img style={{ width: '100%' }} src={item.url} alt="" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}{' '}
        </div>
      </div>
    </>
  );
}
