import { ListResponse } from '@interfaces/CommonInterface';
import { ILogin, IUserLogin } from '@interfaces/UserInterface';
import httpRequest from './Api';
import { ICreateUser, IForgotFassword, IUpdateUser, IUserInfo } from '@interfaces/User';

export type IReqPassword = {
  password_old: string;
  password: string;
  password_confirmation: string;
};
const Auth = {
  login(body: IUserLogin): Promise<ILogin> {
    const url = '/login';
    return httpRequest.post(url, body);
  },
  logout(): Promise<ListResponse<any>> {
    const url = '/logout';
    return httpRequest.post(url);
  },
  register(body: ICreateUser): Promise<{ messages: string }> {
    const url = '/register';
    return httpRequest.post(url, body);
  },
  update(body: IUpdateUser): Promise<{ messages: string }> {
    const url = '/update-user';
    return httpRequest.post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  refetchToken(): Promise<any> {
    const url = '/refresh-token';
    return httpRequest.get(url);
  },
  changePassword(body: IReqPassword): Promise<{ messages: string }> {
    const url = '/change-password';
    return httpRequest.post(url, body);
  },
  getUserCurrent(): Promise<IUserInfo> {
    const url = '/user-info';
    return httpRequest.get(url);
  },
  getOTP(body: { username: string }): Promise<{ messages: string }> {
    const url = '/otp-generate';
    return httpRequest.post(url, body);
  },
  forgotFassword(body: IForgotFassword): Promise<{ messages: string }> {
    const url = '/forgot-password';
    return httpRequest.post(url, body);
  },
};

export default Auth;
