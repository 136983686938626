import * as React from 'react';
import './style.scss';
import { Button, Col, Form, Input, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { convert } from '@utils/convert';
import showMessage from '@utils/helper';
import { IPointParamCreate } from '@interfaces/Point';
import { getPoint, transactionPoint } from '@store/pointSlice';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
export interface IWithdrawComponentProps {}

export default function PointComponent(props: IWithdrawComponentProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { data } = useAppSelector((state) => state.authSlice);
  const [bankKey, setBankKey] = React.useState<number>(0);
  const [param, setParam] = React.useState<IPointParamCreate>();
  const { data: DataGame } = useAppSelector((state) => state.gameSlice);
  const { data: DataBankUser } = useAppSelector((state) => state.bankUserSlice);
  const MySwal = withReactContent(Swal);
  const handleResetFile = () => {
    form.resetFields();
  };
  const handlerChangeBank = (index: number) => {
    setBankKey(index);
  };
  const handleChangePage = () => {
    navigate('/thanh-vien/lich-su-chuyen-diem');
  };
  const handleSubmit = (event: IPointParamCreate) => {
    setParam({ ...param, ...event });
    const pointValue = parseFloat(parseFloat(event.point.toString().replace(/,/g, '')).toFixed(2));
    dispatch(
      transactionPoint({
        param: { ...param, ...event, point: pointValue },
        callback: (messages) => {
          MySwal.fire({
            title: messages,
            customClass: { confirmButton: 'btn-confirm' },
            confirmButtonText: <span onClick={handleChangePage}>Lịch sử chuyển điểm</span>,
          });
          handleResetFile();
        },
      }),
    );
  };
  const handleChange = (event) => {
    if (event.point) {
      const point = event.point.toString().replace(/,/g, '');
      form.setFieldsValue({
        ...event,
        point: convert.numWithCommas(point),
      });
    }
  };
  return (
    <div className="point">
      <div className="rightModule">
        <div className="infoModule">
          <div className="inside-title icon icon-icon32px_Withdraw">
            <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <i style={{ fontSize: '30px' }} className="fa-solid fa-money-bill-transfer"></i>Chuyển điểm
            </span>
          </div>
          <div className="infoModuleLeft">
            <div className="walletWrapper">
              <div className="subModuleList">
                <div className="subModule">
                  <div className="subModuleBody">
                    <div className="inputModuleRow">
                      <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 24 }}
                        onFinish={handleSubmit}
                        layout={'vertical'}
                        autoComplete="off"
                        onValuesChange={handleChange}
                        form={form}
                      >
                        <Col span={12}>
                          <Form.Item
                            label="Số điểm"
                            name="point"
                            rules={[
                              { required: true, message: 'Vui lòng nhập số điểm' },
                              {
                                message: 'Số điểm phải lớn hơn hoặc bằng 1',
                                validator: (_, value) => {
                                  if (
                                    Number(parseInt((value === '' ? 0 : value).replace(/\./g, '').replace(',', ''))) >=
                                    1
                                  ) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject('Số điểm phải lớn hơn hoặc bằng 1');
                                  }
                                },
                              },
                            ]}
                          >
                            <Input placeholder="Nhập số điểm" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Trò chơi gửi"
                            name="game_id"
                            rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                          >
                            <Select
                              allowClear
                              showSearch
                              placeholder="Chọn trò chơi"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={DataGame.listGame?.map((item) => ({
                                value: item.id,
                                label: item.title,
                                disabled: param?.game_transfer_id === item.id,
                              }))}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Trò chơi nhận"
                            name="game_transfer_id"
                            rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                          >
                            <Select
                              allowClear
                              showSearch
                              placeholder="Chọn trò chơi"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={DataGame.listGame?.map((item) => ({
                                value: item.id,
                                label: item.title,
                                disabled: param?.game_id === item.id,
                              }))}
                            />
                          </Form.Item>
                        </Col>
                        <div className="btnGroup">
                          <Col span={12}>
                            <Button htmlType="submit" className="btn-check">
                              Gửi
                            </Button>
                          </Col>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
