import { useAppDispatch, useAppSelector } from '@hook/index';
import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import HeaderComponent from './Header';
import './style.scss';
import { getCurrentUser } from '@store/authSlice';
import MenuHeader from './Menu';
import Footer from './Footer';
import { handleChangeTypeChat, handleResize } from '@store/commonSlice';
import MenuMobile from './MenuMobile';
import FooterMobile from './FooterMobile';
import Subfooter from './SubFooter';
import { getHeader } from '@store/homeSlice';
import Loading from '@components/common/loading';
import ScrollToTop from '@components/common/scroll';
import SiderComponent from '@components/member/sider';
import { getAllBankAdmin, getBankAdminCard } from '@store/bankAdminSlice';
import { getGame } from '@store/gameSlice';
import { getListEvent } from '@store/eventSlice';
import { getAllBankUser } from '@store/bankUserSlice';
import SiderMobileComponent from '@components/member/sider-mobile';
import { getSetting } from '@store/settingSlice';
import Maintenance from '@components/maintenance';
import { EventHandlerPayload, LiveChatWidget } from '@livechat/widget-react';
type Props = {};

const MemberLayout: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { size } = useAppSelector((state) => state.commonSlice);
  const { data: dataSetting } = useAppSelector((state) => state.settingSlice);
  const { Header, Content } = Layout;
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const callback = () => {};
  useEffect(() => {
    dispatch(getCurrentUser({ callback }));
    dispatch(getBankAdminCard());
    dispatch(getAllBankAdmin({ card_type: 1 }));
    dispatch(getGame({}));
    dispatch(getListEvent());
    dispatch(getAllBankUser({}));
  }, []);
  useEffect(() => {
    dispatch(handleResize(window.innerWidth));
    const setSize = () => {
      dispatch(handleResize(window.innerWidth));
    };
    window.addEventListener('resize', setSize);
    return () => window.removeEventListener('resize', setSize);
  }, []);
  useEffect(() => {
    dispatch(getHeader());
    dispatch(getSetting());
  }, []);
  const handleHide = ({ visibility }) => {
    dispatch(handleChangeTypeChat(visibility));
  };
  if (!dataSetting.listSetting)
    return (
      <>
        {' '}
        <Loading loadingDefault={true} />
      </>
    );

  if (!Number(dataSetting.listSetting?.web_status)) {
    return (
      <>
        <Maintenance />
      </>
    );
  }
  return (
    <>
      <Loading />
      <Layout className="layout-member">
        <LiveChatWidget license="13227897" visibility={'minimized'} />
        <HeaderComponent />
        {size > 768 ? <MenuHeader /> : ''}
        <div className="layout-full background-content">
          {' '}
          <div className="layout-max">
            <div className="member-containber">
              {size > 768 ? <SiderComponent /> : <SiderMobileComponent />}
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
        </div>

        {size < 768 ? <Subfooter /> : ''}
        {size > 768 ? <Footer /> : <FooterMobile showPartner={true} />}
      </Layout>
      <ScrollToTop />
    </>
  );
};

export default MemberLayout;
