import { IDataBankItem, IDataRes } from '@interfaces/Bank';
import httpRequest from './Api';

const BankItem = {
  getAllBankItem(): Promise<IDataRes<IDataBankItem>> {
    const url = '/list-banks';
    return httpRequest.get(url);
  },
};

export default BankItem;
