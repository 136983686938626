import React, { useEffect } from 'react';
import './style.scss';
import ModalBasic from './modal';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { deleteBankUser, getAllBankUser } from '@store/bankUserSlice';
import showMessage from '@utils/helper';
import ModalBasicMobile from './modal-mobile';
import { getAllBankItem } from '@store/bankSlice';
export interface IInfoBankComponentProps {}

export default function InfoBankComponent(props: IInfoBankComponentProps) {
  const dispatch = useAppDispatch();
  const { data: DataBankUser } = useAppSelector((state) => state.bankUserSlice);
  const { size } = useAppSelector((state) => state.commonSlice);
  useEffect(() => {
    dispatch(getAllBankItem());
  }, []);
  const handleRemove = (id: number) => {
    dispatch(
      deleteBankUser({
        param: { id },
        callback: (messages) => {
          dispatch(getAllBankUser({}));
          showMessage(messages);
        },
      }),
    );
  };
  return (
    <div className="info-bank">
      <div className="rightModule">
        <div className="infoModule">
          <div className="inside-title icon icon-icon32px_bank-account">
            <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <i style={{ fontSize: '30px' }} className="fa-solid fa-building-columns"></i>Thông tin ngân hàng
            </span>
            <div>Vui lòng cập nhật thông tin tài khoản ngân hàng của bạn trước khi rút tiền.</div>
          </div>
          {/* <div className="accountName">
            Tên tài khoản<span>Client</span>
          </div>{' '} */}
          <div className="walletModule">
            {size > 768 ? (
              <ModalBasic disable={DataBankUser.listBank.length >= 3}>
                <div className="addBank">
                  <div className="addBankName noBank">
                    <i className="icon fa-solid fa-plus"></i>
                    <div className="txt-yellow">Thêm tài khoản</div>
                  </div>
                  <div className="remainder">Số lượng tài khoản: {DataBankUser.listBank.length}/3</div>
                </div>
              </ModalBasic>
            ) : (
              <ModalBasicMobile disable={DataBankUser.listBank.length >= 3}>
                <div className="addBank">
                  <div className="addBankName noBank">
                    <i className="icon fa-solid fa-plus"></i>
                    <div className="txt-yellow">Thêm tài khoản</div>
                  </div>
                  <div className="remainder">Số lượng tài khoản: {DataBankUser.listBank.length}/3</div>
                </div>
              </ModalBasicMobile>
            )}
          </div>
        </div>
        <div className="infoModule">
          <div className="walletModule">
            {DataBankUser.listBank.map((item) => (
              <div key={item.id} className="addBank haveBank">
                <div className="addBankName">
                  <div className="addBankInfo">
                    <div className="bankName">
                      <img alt="BankLogo" src={item.bank.thumbail} />
                    </div>
                  </div>
                  <div className="addBankInfo">
                    <div className="txt-blue2">{item.owner_name}</div>
                    <div className="txt-blue2">{item.card_no}</div>
                  </div>
                  <i onClick={() => handleRemove(item.id)} className="close fa-solid fa-xmark"></i>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
