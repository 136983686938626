import { Action, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import commonSlice from './commonSlice';
import homeSlice from './homeSlice';
import eventSlice from './eventSlice';
import levelSlice from './levelSlice';
import detailSlice from './detailSlice';
import bankAdminSlice from './bankAdminSlice';
import gameSlice from './gameSlice';
import transferSlice from './transferSlice';
import bankUserSlice from './bankUserSlice';
import pointSlice from './pointSlice';
import inviteSlice from './inviteSlice';
import bankSlice from './bankSlice';
import pointPriceSlice from './pointPriceSlice';
import settingSlice from './settingSlice';
import getGameDetail from './gameDetailSlice';
import categorySlice from './categorySlice';
import gameUserSlice from './gameUserSlice';
export const store = configureStore({
  reducer: {
    authSlice: authSlice,
    commonSlice: commonSlice,
    homeSlice: homeSlice,
    eventSlice: eventSlice,
    levelSlice: levelSlice,
    detailSlice: detailSlice,
    bankAdminSlice: bankAdminSlice,
    gameSlice: gameSlice,
    transferSlice: transferSlice,
    bankUserSlice: bankUserSlice,
    pointSlice: pointSlice,
    inviteSlice: inviteSlice,
    bankItemSlice: bankSlice,
    pointPriceSlice: pointPriceSlice,
    settingSlice: settingSlice,
    getGameDetail: getGameDetail,
    categorySlice: categorySlice,
    gameUserSlice: gameUserSlice,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
