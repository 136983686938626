import { CloseOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';

const DetailModal = ({ children, title = 'Chi tiết', open, onClose, centered = false }) => {
  return (
    <Modal
      closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
      footer={null}
      title={title}
      open={open}
      onCancel={onClose}
    >
      {children}
    </Modal>
  );
};

export default DetailModal;
