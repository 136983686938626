import { useAppDispatch, useAppSelector } from '@hook/index';
import { IEvent } from '@interfaces/Event';
import * as React from 'react';
import ModalEventDetail from '../modal';
import { handleChangeTypeChat, handleOpen } from '@store/commonSlice';
import { useNavigate } from 'react-router-dom';

export interface INewsEventProps {}

export default function NewsEvent(props: INewsEventProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.eventSlice);
  const { data: AuthData } = useAppSelector((state) => state.authSlice);
  const [visibility, setVisibility] = React.useState<any>('minimized');
  const handleCheckLogin = () => {
    dispatch(handleOpen(true));
  };
  const handlJoin = (item: IEvent) => {
    switch (item.apply_type) {
      case 2:
        setVisibility('maximized');
        dispatch(handleChangeTypeChat('maximized'));
        break;
      case 3:
        navigate('/thanh-vien/nap-tien', { state: { typeEvent: 3 } });
        break;
      case 4:
        window.open(item.apply_url);
        break;
      default:
        break;
    }
  };
  return (
    <div className="proListNew">
      {data.listEvent?.map((item, index) => (
        <div className="item" key={index}>
          <div className="new" />
          <div className="promoImg">
            <img src={item.cover_image} alt="20M THƯỞNG CHÀO MỪNG SABA SPORTS" />
          </div>
          <div className="promoInfo">
            <div className="txt-darkBlue">{item.title}</div>
            <div className="txt-gray">{item.rule_content}</div>
            <div className="btnGroup">
              {!(data.apply_type === 1) && (
                <button onClick={!AuthData.isLogin ? handleCheckLogin : () => handlJoin(item)} className="yellow">
                  THAM GIA NGAY
                </button>
              )}
              <ModalEventDetail
                handleCheckLogin={!AuthData.isLogin ? handleCheckLogin : () => handlJoin(item)}
                data={item}
              >
                {' '}
                THÊM THÔNG TIN
              </ModalEventDetail>
            </div>
          </div>
          <div className="remain">
            <div className="icon icon-icon32px_time">
              {item.date_end_event === null ? (
                <>Hết hạn</>
              ) : (
                <>
                  THỜI GIAN CÒN LẠI<div className="date">{item.date_end_event}</div>
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
