import { IPermissions } from '@interfaces/User';
import { notification } from 'antd';

const showMessage = (message?: string) => {
  if (!message) return;
  if (message && message === 'Thành công') {
    notification.success({ message, duration: 3 });
  } else {
    notification.error({ message, duration: 3 });
  }
};

export default showMessage;

export const isCheckRole = (permissions: IPermissions[], permission: string) => {
  return permissions.findIndex((item) => item.name.includes(permission)) !== -1;
};
