import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDataEvent, IEventParam, StateEvent } from '@interfaces/Event';
import Event from '@services/Event';

const initialState: StateEvent<IDataEvent> = {
  data: {
    listEvent: [],
    eventDetail: null,
    category: null,
    current_page: 1,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllEvent = createAsyncThunk(
  'Event/getAllEvent',
  async (payload: Partial<IEventParam>, { getState }) => {
    try {
      const { data: dataRes } = await Event.getAllEvent({ ...payload, paginate: 15 });
      return {
        listEvent: [...dataRes.data.data],
        current_page: dataRes.data.current_page,
        total: dataRes.data.total,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const getListEvent = createAsyncThunk('Event/getListEvent', async (_, { getState }) => {
  try {
    const { data: dataRes } = await Event.getAllList();
    return {
      listEvent: [...dataRes.data],
    };
  } catch (error) {
    console.log(error);
  }
});

export const getEventDetail = createAsyncThunk(
  'Event/getEventDetail',
  async (payload: Partial<IEventParam>, { getState }) => {
    try {
      const { data: dataRes } = await Event.getEventId({ ...payload });
      return {
        eventDetail: { ...dataRes },
      };
    } catch (error) {
      console.log(error);
    }
  },
);
export const getCategory = createAsyncThunk('Event/getCategory', async (_, { getState }) => {
  try {
    const { data: dataRes } = await Event.getcategory();
    return {
      category: { ...dataRes.data },
    };
  } catch (error) {
    console.log(error);
  }
});

const eventSlice = createSlice({
  name: 'Event',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Event/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Event/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Event/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default eventSlice.reducer;
