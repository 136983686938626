import { useAppDispatch, useAppSelector } from '@hook/index';
import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import './style.scss';
import { getCurrentUser } from '@store/authSlice';
import { handleResize } from '@store/commonSlice';
import { getHeader } from '@store/homeSlice';
import Loading from '@components/common/loading';
import ScrollToTop from '@components/common/scroll';
import FooterMobile from '../DefaultLayout/FooterMobile';
import { getSetting } from '@store/settingSlice';
import Maintenance from '@components/maintenance';
type IOtherLayout = {
  title: string;
};

const OtherLayout = (props: IOtherLayout) => {
  const { title } = props;
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { size } = useAppSelector((state) => state.commonSlice);
  const { data: dataSetting } = useAppSelector((state) => state.settingSlice);
  const { Header, Content } = Layout;
  const callback = () => {};
  useEffect(() => {
    dispatch(getCurrentUser({ callback }));
  }, []);
  useEffect(() => {
    dispatch(handleResize(window.innerWidth));
    const setSize = () => {
      dispatch(handleResize(window.innerWidth));
    };
    window.addEventListener('resize', setSize);
    return () => window.removeEventListener('resize', setSize);
  }, []);
  useEffect(() => {
    dispatch(getHeader());
    dispatch(getSetting());
  }, []);
  if (!dataSetting.listSetting)
    return (
      <>
        {' '}
        <Loading loadingDefault={true} />
      </>
    );

  if (!Number(dataSetting.listSetting?.web_status)) {
    return (
      <>
        <Maintenance />
      </>
    );
  }
  return (
    <>
      <Loading />
      <Layout className="layout-other">
        <div className="header">{title}</div>
        <Content className="background-content">
          <Outlet />
        </Content>
        {size < 768 && <FooterMobile />}
      </Layout>
      <ScrollToTop />
    </>
  );
};

export default OtherLayout;
