import httpRequest from './Api';
import { IDataParamGameUser, IDataRes, IGameUser } from '@interfaces/GameUser';

const GameUser = {
  getAllGame({ ...rest }: Partial<IDataParamGameUser>): Promise<IDataRes> {
    const url = '/list-user-games';
    return httpRequest.get(url, { params: { ...rest } });
  },
};

export default GameUser;
