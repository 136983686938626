import { IDataResGame, IGame, IParamGame } from '@interfaces/Game';
import httpRequest from './Api';

const Game = {
  getAllGame({ ...rest }: Partial<IParamGame>): Promise<IDataResGame<IGame>> {
    const url = '/list-games';
    return httpRequest.get(url, { params: { ...rest } });
  },
};

export default Game;
