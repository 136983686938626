import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Game from '@services/Game';
import { IDataGameUser, IDataParamGameUser, StateGameUser } from '@interfaces/GameUser';
import GameUser from '@services/GameUser';

const initialState: StateGameUser<IDataGameUser> = {
  data: {
    listGame: [],
    current_page: 1,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getGameUser = createAsyncThunk(
  'GameUser/getAllGame',
  async (payload: Partial<IDataParamGameUser>, { getState }) => {
    try {
      const { data: dataRes } = await GameUser.getAllGame({ ...payload });
      return {
        listGame: [...dataRes.data],
      };
    } catch (error) {
      console.log(error);
    }
  },
);

const gameUserSlice = createSlice({
  name: 'GameUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('GameUser/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('GameUser/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('GameUser/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default gameUserSlice.reducer;
