import { Button, Drawer, Space } from 'antd';
import { RadioChangeEventTarget } from 'antd/lib/radio';
import * as React from 'react';
import './style.scss';
import MenuComponent from '../menu';
import VisibleAmount from '../visible';
import { useAppSelector } from '@hook/index';
import { CloseOutlined } from '@ant-design/icons';
import VN from '@assets/images/la_co_vn.png';
import EN from '@assets/images/la_co_en.png';
import { IMenu } from '@interfaces/Home';
import { Link } from 'react-router-dom';
export interface INavProps {}

export default function Nav(props: INavProps) {
  const [open, setOpen] = React.useState(false);
  const { size } = useAppSelector((state) => state.commonSlice);
  const { data } = useAppSelector((state) => state.homeSlice);
  const { data: DataAuth } = useAppSelector((state) => state.authSlice);
  const { data: dataSetting } = useAppSelector((state) => state.settingSlice);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (size > 768) {
      setOpen(false);
    }
  }, [size]);

  const TopMenu = () => (
    <>
      <div className="top-menu">
        <div className="logo">
          <img src={data.header.logo} alt="logo" />
        </div>
        {DataAuth.isLogin && (
          <>
            {' '}
            <p className="username">Chào mừng {DataAuth.username}</p>
            {/* <div className="balance">
              <span>Số dư</span>
              <VisibleAmount amount={DataAuth.ml_money} />
            </div> */}
          </>
        )}
        <div className="close-nav" onClick={onClose}>
          <CloseOutlined />
        </div>
      </div>
      {DataAuth.isLogin && (
        <div className="thBar">
          <Link className="icon icon-deposit" to="/thanh-vien/nap-tien">
            <i className="fa-solid fa-building-columns"></i>
            <span>Nạp tiền</span>
          </Link>
          <Link className="icon icon-transfer" to="/thanh-vien/chuyen-tien">
            <i className="fa-solid fa-dollar-sign"></i>
            <span>Chuyển điểm</span>
          </Link>
          <Link className="icon icon-withdrawal" to="/thanh-vien/rut-tien">
            <i className="fa-solid fa-clock-rotate-left"></i>
            <span>Rút tiền</span>
          </Link>
        </div>
      )}
    </>
  );

  return (
    <>
      <div className="btn-nav" onClick={showDrawer}>
        {' '}
        <i className="fa-solid fa-bars"></i>
      </div>
      <Drawer
        className="nav-wrapper"
        bodyStyle={{ padding: 0 }}
        width={'100%'}
        title={TopMenu()}
        placement={'left'}
        onClose={onClose}
        closable={false}
        open={open}
      >
        <div className="menu-body-container">
          <MenuComponent handleClose={onClose} menu={data.header?.menu} />
          <div className="contact">
            <div className="title">LIÊN HỆ</div>
            <div className="list-social">
              <a href={dataSetting?.telegram} target="_blank" rel="noreferrer noopener">
                <img src={require('@assets/telegram.png')} alt="Telegram" />
              </a>
              <a href={dataSetting?.zalo} target="_blank" rel="noreferrer noopener">
                <img src={require('@assets/zalo.png')} alt="Zalo" />
              </a>
              <a href={dataSetting?.viber} target="_blank" rel="noreferrer noopener">
                <img src={require('@assets/viber.png')} alt="Viber" />
              </a>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
