import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Pagination } from 'swiper';
import './style.scss';
import { ISlide } from '@interfaces/Home';

export type IBanner = {
  data: ISlide[];
};

export default function Banner(props: IBanner) {
  const { data } = props;
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>`;
    },
  };

  return (
    <>
      {data.length > 0 && (
        <Swiper
          pagination={pagination}
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              {item.jump_link ? (
                <a
                  href={item.jump_link || ''}
                  target="_blank"
                  rel="noreferrer"
                  style={{ width: '100%', display: 'block' }}
                >
                  <img style={{ width: '100%' }} src={item.url} alt="" />
                </a>
              ) : (
                <div style={{ width: '100%', display: 'block' }}>
                  <img style={{ width: '100%' }} src={item.url} alt="" />
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}{' '}
    </>
  );
}
