import React, { useState } from 'react';
import './style.scss';
import { Button, Col, Form, Input } from 'antd';
import { useAppDispatch } from '@hook/index';
import { IReqPassword } from '@services/Auth';
import { changePass } from '@store/authSlice';
import showMessage from '@utils/helper';
import { LockOutlined } from '@ant-design/icons';
export interface IChangePassComponentProps {}

export default function ChangePassComponent(props: IChangePassComponentProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [param, setParam] = useState<IReqPassword>();
  const handleSubmit = (event: IReqPassword) => {
    setParam({ ...param, ...event });
    dispatch(
      changePass({
        param: { ...param, ...event },
        callback: (messages) => {
          showMessage(messages);
          handleResetFile();
        },
      }),
    );
  };
  const handleResetFile = () => {
    form.resetFields();
  };
  return (
    <div className="change-pass">
      <div className="rightModule">
        <div className="infoModule">
          <div className="inside-title icon icon-icon32px_password">
            <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <i style={{ fontSize: '30px' }} className="fa-solid fa-lock"></i>Đổi mật khẩu
            </span>
            <div>Sửa đổi mật khẩu khi đăng nhập, sửa đổi mật khẩu thường xuyên có lợi cho việc bảo mật tài khoản.</div>
          </div>
          <div className="changePassWord">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              form={form}
              wrapperCol={{ span: 24 }}
              layout={'vertical'}
              autoComplete="off"
              onFinish={handleSubmit}
              className="form-changepass"
            >
              <Col span={24}>
                <Form.Item name="password_old" rules={[{ required: true, message: 'Vui lòng nhập mật khẩu hiện tại' }]}>
                  <Input.Password placeholder="Nhập mật khẩu hiện tại" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="password" rules={[{ required: true, message: 'Vui lòng nhập mật khẩu mới' }]}>
                  <Input.Password placeholder="Nhập mật khẩu mới" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="password_confirm"
                  rules={[{ required: true, message: 'Vui lòng xác nhận mật khẩu mới' }]}
                >
                  <Input.Password placeholder="Xác nhận mật khẩu mới" />
                </Form.Item>
              </Col>

              <div className="btnGroup">
                <Col span={24}>
                  <Button htmlType="submit" className="btn-check">
                    Gửi
                  </Button>
                </Col>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
