import ModalFooter from '@components/layout/modal-footer';
import { useAppSelector } from '@hook/index';
import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';

export interface IFooterMobileProps {
  showPartner?: boolean;
}

export default function FooterMobile(props: IFooterMobileProps) {
  const { data: dataAuth } = useAppSelector((state) => state.authSlice);
  const { showPartner } = props;
  return (
    <>
      <footer className="footerNew">
        <NavLink to="/khuyen-mai">
          {' '}
          <i className="fa-solid fa-gift"></i>
          <span>Khuyến mãi</span>
        </NavLink>

        <NavLink to="/" className="open">
          <div className="hoticon" />
          <i className="fa-solid fa-house"></i>
          <span>Trang Chủ</span>
        </NavLink>
        <a target="_blank" rel="noreferrer" href="https://direct.lc.chat/12171870" className="">
          <i className="fa-solid fa-headphones-simple"></i>
          <span>Live Chat</span>
        </a>
        {dataAuth.isLogin ? (
          <NavLink to="/thanh-vien/ho-so" className="open">
            <i className="fa-solid fa-user"></i>
            <span>Tôi</span>
          </NavLink>
        ) : (
          <NavLink to="/khac" className="open">
            <span className="icon">•••</span>
            <span>Thêm</span>
          </NavLink>
        )}
      </footer>
      {showPartner && (
        <div className="partner">
          <div>
            <img alt="logo" src={require('@assets/footer/platform/saba.png')} />

            <img alt="logo" src={require('@assets/footer/platform/sbo.png')} />

            <img alt="logo" src={require('@assets/footer/platform/ag.png')} />

            <img alt="logo" src={require('@assets/footer/platform/ae.png')} />

            <img alt="logo" src={require('@assets/footer/platform/n2live.png')} />

            <img alt="logo" src={require('@assets/footer/platform/pp.png')} />

            <img alt="logo" src={require('@assets/footer/platform/bg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/yb.png')} />

            <img alt="logo" src={require('@assets/footer/platform/mg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/pt.png')} />

            <img alt="logo" src={require('@assets/footer/platform/pg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/sg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/yg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/cq9.png')} />

            <img alt="logo" src={require('@assets/footer/platform/jili.png')} />

            <img alt="logo" src={require('@assets/footer/platform/yl.png')} />

            <img alt="logo" src={require('@assets/footer/platform/v8.png')} />

            <img alt="logo" src={require('@assets/footer/platform/kingmaker.png')} />

            <img alt="logo" src={require('@assets/footer/platform/tcg.png')} />

            <img alt="logo" src={require('@assets/footer/platform/rt.png')} />

            <img alt="logo" src={require('@assets/footer/platform/evolution.png')} />

            <img alt="logo" src={require('@assets/footer/platform/habanero.png')} />

            <img alt="logo" src={require('@assets/footer/platform/simpleplay.png')} />

            <img alt="logo" src={require('@assets/footer/platform/sk.png')} />
          </div>
        </div>
      )}
    </>
  );
}
