import { PayloadCallBack } from '@interfaces/CommonInterface';
import { IDataPoint, IPointParam, IPointParamCreate, StatePoint } from '@interfaces/Point';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Point from '@services/Point';

const initialState: StatePoint<IDataPoint> = {
  data: {
    listPoint: [],
  },
  loading: false,
  error: '',
};

export const getPoint = createAsyncThunk('Point/getAllPoint', async (payload: Partial<IPointParam>, { getState }) => {
  try {
    const { data: dataRes } = await Point.getAllPoint({ ...payload });
    return {
      listPoint: [...dataRes.data],
      current_page: dataRes.current_page,
      total: dataRes.total,
    };
  } catch (error) {
    console.log(error);
  }
});

export const transactionPoint = createAsyncThunk(
  'Point/deposit',
  async (payload: PayloadCallBack<IPointParamCreate>, { getState }) => {
    try {
      const { messages } = await Point.transactionPoint({ ...payload.param });
      payload.callback(messages);
    } catch (error) {
      console.log(error);
    }
  },
);

const pointSlice = createSlice({
  name: 'Point',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Point/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Point/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Point/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default pointSlice.reducer;
