import { IDataBankItem, StateBankItem } from '@interfaces/Bank';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BankItem from '@services/Bank';

const initialState: StateBankItem<IDataBankItem> = {
  data: {
    listBank: [],
  },
  loading: false,
  error: '',
};

export const getAllBankItem = createAsyncThunk('BankItem/getAllBankItem', async (_, { getState }) => {
  try {
    const { data: dataRes } = await BankItem.getAllBankItem();
    return {
      listBank: dataRes.data,
    };
  } catch (error) {
    console.log(error);
  }
});

const bankItemSlice = createSlice({
  name: 'BankItem',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankItem/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankItem/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankItem/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default bankItemSlice.reducer;
