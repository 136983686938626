import UploadMutipleImage from '@components/common/upload/UploadMutipleImage';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { ICreateUser, IUpdateUser } from '@interfaces/User';
import { creatUser, getCurrentUser, updateUser } from '@store/authSlice';
import showMessage from '@utils/helper';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import * as React from 'react';
import type { Moment } from 'moment';
import './style.scss';
import moment from 'moment';
import dayjs from 'dayjs';

export interface IEditProfileProps {
  handleShow: () => void;
}

const genderList = [
  {
    label: 'Nam',
    value: 0,
  },
  {
    label: 'Nữ',
    value: 1,
  },
];

export default function EditProfile({ handleShow }: IEditProfileProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { data } = useAppSelector((state) => state.authSlice);

  const [param, setParam] = React.useState<IUpdateUser>({
    id: data.id,
    gender: genderList[Number(data.gender)],
    email: data.email,
    mobile: data.mobile,
    birthday: data.birthday ? moment(data.birthday, 'YYYY-MM-DD') : '',
    avatar: data.avatar,
    zalo: data.zalo,
    telegram: data.telegram,
    viber: data.viber,
    real_name: data.real_name,
    'image_other[]': data.image_other || null,
  });
  const handleResetFile = () => {
    form.resetFields();
  };
  const handleSubmit = (event) => {
    setParam({ ...param, ...event, birthday: event.birthday.format('YYYY-MM-DD') });
    dispatch(
      updateUser({
        param: {
          ...param,
          ...event,
          birthday: event.birthday.format('YYYY-MM-DD'),
          gender: typeof event.gender === 'object' ? event.gender.value : event.gender,
        },
        callback: (message) => {
          dispatch(
            getCurrentUser({
              callback: () => {
                showMessage(message);
                handleResetFile();
                handleShow();
              },
            }),
          );
        },
      }),
    );
  };
  const handleChangeImg = (imgList: any) => {
    if (imgList.length === 0) return;
    setParam({ ...param, avatar: imgList[0] });
  };
  const handleChangeImgMutiple = (imgList: any) => {
    if (!imgList) return;
    setParam({ ...param, 'image_other[]': imgList });
  };
  const handleClose = () => {
    handleShow();
  };
  return (
    <div className="edit-profile">
      <button className="btn-back" onClick={handleClose}>
        <i className="fa-solid fa-arrow-left"></i>
      </button>
      <Form
        name="basic"
        className="form-register"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={param}
        onFinish={handleSubmit}
        layout={'vertical'}
        autoComplete="off"
        form={form}
      >
        <Row gutter={10} className="row-form" justify="center">
          <Col span={24}>
            <Form.Item className="avatar" label="Ảnh đại diện">
              <UploadMutipleImage defaultValue={data.avatar || ''} limit={1} handleChangeImg={handleChangeImg} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} className="row-form" justify="center">
          <Col span={24}>
            <Form.Item className="avatar" label="CMND">
              <UploadMutipleImage
                defaultValue={data.image_other || []}
                limit={6}
                handleChangeImg={handleChangeImgMutiple}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} className="row-form" justify="center">
          <Col span={12}>
            <Form.Item
              label="Tên thật"
              name="real_name"
              rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Ngày sinh"
              name="birthday"
              rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} className="row-form" justify="center">
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
            >
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Số điện thoại"
              name="mobile"
              rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} className="row-form" justify="center">
          <Col span={12}>
            <Form.Item
              label="Giớ tính"
              name="gender"
              rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={genderList}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Zalo" name="zalo">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} className="row-form" justify="center">
          <Col span={12}>
            <Form.Item label="Telegram" name="telegram">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Viber" name="viber">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <div className="btnGroup">
          <Row gutter={10} className="row-form" justify="center">
            <Col span={12}>
              <Button htmlType="submit" className="btn-check">
                Cập nhật
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}
