import { Popover } from 'antd';
import * as React from 'react';
import './style.scss';

export interface IPopoverArowProps {
  content: () => React.ReactElement;
  children: React.ReactNode;
  classCustom?: string;
  allowArow?: boolean;
  trigger?: string;
  removeArrowIcon?: boolean;
}

export default function PopoverArow({
  content,
  children,
  classCustom,
  allowArow,
  trigger,
  removeArrowIcon,
}: IPopoverArowProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const handleChange = (isOpen: boolean) => {
    setOpen(isOpen);
  };
  return (
    <Popover
      overlayClassName={classCustom}
      showArrow={allowArow || false}
      placement="bottom"
      content={content()}
      onOpenChange={handleChange}
      trigger={trigger || 'click'}
    >
      {!removeArrowIcon ? (
        <div className={`arrow-change ${open ? 'arrow-active' : ''}`}>
          {children}{' '}
          <div className={`arrow-icon ${open ? 'arrow-show' : ''}`}>
            <i className="fa-solid fa-chevron-down"></i>
          </div>
        </div>
      ) : (
        children
      )}
    </Popover>
  );
}
