import { IDataSetting, StateSetting } from '@interfaces/Setting';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Setting from '@services/Setting';

const initialState: StateSetting<IDataSetting> = {
  data: {
    listSetting: null,
  },
  loading: false,
  error: '',
};

export const getSetting = createAsyncThunk('Setting/getSetting', async (_, { getState }) => {
  try {
    const { data: dataRes } = await Setting.getSetting();
    return {
      listSetting: { ...dataRes.data },
    };
  } catch (error) {
    console.log(error);
  }
});

const settingSlice = createSlice({
  name: 'Setting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Setting/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Setting/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Setting/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default settingSlice.reducer;
