import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDataHome, StateHome } from '@interfaces/Home';
import Home from '@services/Home';

const initialState: StateHome<IDataHome> = {
  data: {
    header: {
      logo: '',
      favicon: '',
      menu: null,
    },
    body: {
      outstanding: [],
      slide: [],
      marquee: '',
      posts: [],
    },
    footer: null,
  },
  loading: false,
  error: '',
};

export const getHeader = createAsyncThunk('Home/getHeader', async (_, { getState }) => {
  try {
    const { data: dataRes } = await Home.getHeader();
    return {
      header: {
        ...dataRes.data,
      },
    };
  } catch (error) {
    console.log(error);
  }
});

export const getBody = createAsyncThunk('Home/getBody', async (_, { getState }) => {
  try {
    const { data: dataRes } = await Home.getBody();
    return {
      body: {
        ...dataRes.data,
      },
    };
  } catch (error) {
    console.log(error);
  }
});
export const getFooter = createAsyncThunk('Home/getFooter', async (_, { getState }) => {
  try {
    const { data: dataRes } = await Home.getFooter();
    return {
      footer: {
        ...dataRes.data,
      },
    };
  } catch (error) {
    console.log(error);
  }
});

const HomeSlice = createSlice({
  name: 'Home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Home/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Home/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Home/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default HomeSlice.reducer;
