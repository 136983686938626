import HistoryInviteList from '@components/table-mobile/HistoryInvite';
import { useAppSelector } from '@hook/index';
import { ParamReq } from '@interfaces/CommonInterface';
import { IInvite } from '@interfaces/Invite';
import { convert } from '@utils/convert';
import { Pagination, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
export interface IListMember {
  data: IInvite[];
  current_page: number;
  total: number;
  loading: boolean;
  handleChangeParam: (param: ParamReq) => void;
}

export default function History(props: IListMember) {
  const { data, current_page, total, loading, handleChangeParam } = props;
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  /** data for column */
  const columns: ColumnsType<IInvite> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 50,
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'member',
      key: 'member',
      align: 'center',
      width: 50,
      render: (_, record) => <Space size="middle">{record.member.username || ''}</Space>,
    },

    {
      title: 'Tạo lúc	',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: 50,
      render: (_, record) => <Space size="middle">{convert.convertDateFormat(record.created_at)}</Space>,
    },
  ];

  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <div className="table-wrap">
        <div className="m-top">
          <Table
            className="table wrap-col desktop"
            scroll={{ x: 1024 }}
            loading={loading}
            pagination={false}
            columns={columns || 1}
            dataSource={data}
            rowKey={(record) => record.id}
          />
          <div className="mobile">
            <HistoryInviteList data={data} />
          </div>
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination onChange={ChangePage} current={current_page} total={total} showSizeChanger={false} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
