import { IDataRes, IDataSetting } from '@interfaces/Setting';
import httpRequest from './Api';

const Setting = {
	getSetting(): Promise<IDataRes<IDataSetting>> {
    const url = '/settings';
    return httpRequest.get(url);
  },
};

export default Setting;
