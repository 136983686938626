import * as React from 'react';
import Banner from './banner';
import Offers from './offers';
import HotNews from './hot-news';
import Info from './info';
import { useAppDispatch, useAppSelector } from '@hook/index';
import NewsMobile from './news-mobile';
import { getBody } from '@store/homeSlice';
import MenuMobile from '@shared/layouts/DefaultLayout/MenuMobile';
import './style.scss';
import Marquees from './marquee';
import SEO from '@components/common/seo';
import { Link } from 'react-router-dom';
export interface IHomeComponentProps {}

export default function HomeComponent(props: IHomeComponentProps) {
  const dispatch = useAppDispatch();
  const [linkEvent, setLinkEvent] = React.useState<boolean>(true);
  const [linkWheel, setLinkWheel] = React.useState<boolean>(true);
  const { size } = useAppSelector((state) => state.commonSlice);
  const { data } = useAppSelector((state) => state.homeSlice);
  const { data: dataAuth } = useAppSelector((state) => state.authSlice);
  const { data: dataSetting } = useAppSelector((state) => state.settingSlice);
  React.useEffect(() => {
    dispatch(getBody());
  }, []);
  const handleNewEvent = (event: any) => {};
  return (
    <>
      <SEO />
      {size < 768 ? <MenuMobile isCheckLogin={dataAuth.isLogin} /> : ''}
      <Banner data={data.body.slide} />
      {/* <Offers /> */}
      <Marquees content={data.body.marquee} />
      {size > 768 ? (
        <HotNews data={data.body.outstanding} />
      ) : (
        <NewsMobile outstanding={data.body.outstanding} menu={data.header.menu} />
      )}
      <div className="layout-full">
        <div className="layout-max" style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
          <div style={{ width: '50%' }}>
            <Link to={`/cap-bac`}>
              <img style={{ maxWidth: '100%' }} src={require('@assets/body/slide3.jpg')} alt="" />
            </Link>
          </div>
          <div style={{ width: '50%' }}>
            <Link to={`/khuyen-mai`}>
              <img style={{ maxWidth: '100%' }} src={require('@assets/body/slide6.jpg')} alt="" />
            </Link>
          </div>
        </div>
      </div>
      {size > 768 ? <Info /> : ''}
      <div className="quickLink">
        <div className="btn-contact">
          <div>
            {' '}
            <i className="fa-solid fa-chevron-up"></i>
            LIÊN HỆ
          </div>
        </div>
        <div>
          <ul>
            <li>
              <a href={dataSetting?.telegram} target="_blank" rel="noreferrer">
                <img src={require('@assets/quickLink/telegram.png')} alt="img-Telegram" />
                Telegram
              </a>
              <img src={dataSetting?.listSetting?.telegram_qrcode} alt="Telegram" />
            </li>
            <li>
              <a href={dataSetting?.zalo} target="_blank" rel="noreferrer">
                <img src={require('@assets/quickLink/zalo.png')} alt="img-Zalo" />
                Zalo
              </a>
              <img src={dataSetting?.listSetting?.zalo_qrcode} alt="Zalo" />
            </li>
            <li>
              <a href={dataSetting?.viber} target="_blank" rel="noreferrer">
                <img src={require('@assets/quickLink/viber.png')} alt="img-Viber" />
                Viber
              </a>
              <img src={dataSetting?.listSetting?.viber_qrcode} alt="Viber" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
