import { IPageParam, IDataRes, IGameDetail } from '@interfaces/gameDetail';
import httpRequest from './Api';

const GameDetail = {
  getDetailSlug({ ...rest }: Partial<IPageParam>): Promise<IDataRes<IGameDetail>> {
    const url = 'menu-detail';
    return httpRequest.get(url, { params: { ...rest } });
  },
};

export default GameDetail;
