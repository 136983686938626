import * as React from 'react';
import './style.scss';
import { useAppSelector } from '@hook/index';
import Logout from '@feature/auth/logout';
import { NavLink } from 'react-router-dom';
import DepositPC from '@components/deposit/index_pc';
export interface IMemberComponentProps {}

export default function MemberComponent(props: IMemberComponentProps) {
  const { data } = useAppSelector((state) => state.authSlice);
  return <DepositPC />;
}
