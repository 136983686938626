import * as React from 'react';
import './style.scss';
import { IOutstanding } from '@interfaces/Home';
import ImgErorr from '@assets/images/picture.png';
import { Link } from 'react-router-dom';
export interface IHotNewsProps {
  data: IOutstanding[];
}

export default function HotNews(props: IHotNewsProps) {
  const { data } = props;
  return (
    <div className="layout-full hot-news">
      <div className="layout-max hot-news-container">
        <h2 className="title">Nổi bật</h2>
        <div className="topGame">
          {data.slice(0, 4).map((item) => (
            <button key={item.id}>
              <Link to={`/tro-choi/${item?.slug}`}>
                {' '}
                <img className="gameImg" alt="Thể thao-alt" src={item.image || ImgErorr} />
                <div className="title">{item.title}</div>
              </Link>
            </button>
          ))}
        </div>
        <div className="bottomGame">
          {data.slice(4, data.length).map((item) => (
            <button key={item.id}>
              <div className="item">
                <Link to={`/tro-choi/${item?.slug}`}>
                  {' '}
                  <img className="gameImg" alt="Thể thao-alt" src={item.image || ImgErorr} />
                  <div className="title">{item.title}</div>
                </Link>
              </div>
            </button>
          ))}
        </div>

        <div className="sub-list"></div>
      </div>
    </div>
  );
}
