import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Select, message, notification } from 'antd';
import './style.scss';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { IUserLogin } from '@interfaces/UserInterface';
import Loading from '@components/common/loading';
import { authLogin, getCurrentUser } from '@store/authSlice';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { handleOpen } from '@store/commonSlice';
import { IParamCreateBankUser } from '@interfaces/BankUser';
import { createBankUser, getAllBankUser } from '@store/bankUserSlice';
import showMessage from '@utils/helper';
type IModalBasic = {
  children: React.ReactNode;
  disable?: boolean;
};
export default function ModalBasicMobile({ children, disable }: IModalBasic) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { data } = useAppSelector((state) => state.homeSlice);
  const { data: DataBankAdmin } = useAppSelector((state) => state.bankAdminSlice);
  const { data: DataBankItem } = useAppSelector((state) => state.bankItemSlice);
  const { openLogin } = useAppSelector((state) => state.commonSlice);
  const [param, setParam] = useState<IParamCreateBankUser>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (!openLogin) return;
    setIsModalOpen(openLogin);
  }, [openLogin]);
  const handleShow = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(handleOpen(false));
  };
  const handleSubmit = (event: IParamCreateBankUser) => {
    setParam({ ...param, ...event });
    dispatch(
      createBankUser({
        param: { ...param, ...event },
        callback: (messages) => {
          dispatch(getAllBankUser({}));
          showMessage(messages);
          setIsModalOpen(false);
          dispatch(handleOpen(false));
        },
      }),
    );
  };
  const handleChange = (event) => {
    if (event.bank_id) {
      form.setFieldsValue({
        ...event,
        bank_type: DataBankItem.listBank.find((item) => item.id === event.bank_id)?.key,
      });
    } else if (event.bank_type) {
      form.setFieldsValue({
        ...event,
        bank_id: DataBankItem.listBank.find((item) => item.key === event.bank_type)?.id,
      });
    }
  };

  return (
    <div className="modale-basic">
      <div className={`open-btn ${disable && 'disable'}`} onClick={handleShow}>
        {children}
      </div>
      <Modal
        className="modal-login-mobile add-bank-mobile"
        closable={true}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="modal-login-mobile-container">
          <Loading sx="modal-loading-container" />
          <div className="logo">
            <img src={data.header.logo} alt="" />
          </div>

          <Form
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            style={{ width: '100%' }}
            autoComplete="off"
            onFinish={handleSubmit}
            onValuesChange={handleChange}
          >
            <div className="pop">
              <div className="addBankpop active">
                <span className="title">Thêm tài khoản</span>
                <div className="subtitle">
                  Vui lòng cập nhật thông tin tài khoản ngân hàng của bạn trước khi rút tiền.
                </div>
                {/* <div className="txt">
                  <div>
                    <span className="txt-blue2">Họ Tên thật</span>
                    <span>Client</span>
                  </div>
                  <div className="iconModule">
                    <i className="icon icon-information-solid" />
                  </div>
                </div> */}
                <Col span={24}>
                  <Form.Item
                    label=""
                    name="bank_id"
                    rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Nhập phương thức thanh toán"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={DataBankItem.listBank?.map((item) => ({ value: item.id, label: item.name }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label=""
                    name="owner_name"
                    rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                  >
                    <Input placeholder="Tên người dùng" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label=""
                    name="card_no"
                    rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                  >
                    <Input placeholder="số tài khoản" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label=""
                    name="bank_type"
                    rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Nhập loại ngân hàng"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={DataBankItem.listBank?.map((item) => ({ value: item.key, label: item.key }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label=""
                    name="bank_address"
                    rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                  >
                    <Input placeholder="Địa chỉ" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label=""
                    name="status"
                    rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Trạng thái"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={[
                        { value: 0, label: 'Tắt' },
                        { value: 1, label: 'Bật' },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label=""
                    name="phone"
                    rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                  >
                    <Input placeholder="Số điện thoại" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label=""
                    name="card_type"
                    rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Nhập phương thức thanh toán"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={Object.values(DataBankAdmin.method || {}).map((item, index) => ({
                        value: index + 1,
                        label: item,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <div />
                <div className="btnGroup">
                  <button className="btn-cancel" onClick={handleCancel} type="button">
                    Hủy
                  </button>
                  <Button className="btn-check" htmlType="submit">
                    Thêm mới
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
