import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IDataBankUser,
  StateBankUser,
  IParamListBankUser,
  IParamCreateBankUser,
  IParamDeleteBankUser,
} from '@interfaces/BankUser';
import BankUser from '@services/BankUser';
import { PayloadCallBack } from '@interfaces/CommonInterface';

const initialState: StateBankUser<IDataBankUser> = {
  data: {
    listBank: [],
    current_page: 1,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllBankUser = createAsyncThunk(
  'BankUser/getAllBankUser',
  async (payload: Partial<IParamListBankUser>, { getState }) => {
    try {
      const { data: dataRes } = await BankUser.getAllBankUser({ ...payload });
      return {
        listBank: [...dataRes.data],
        current_page: dataRes.current_page,
        total: dataRes.total,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const createBankUser = createAsyncThunk(
  'BankUser/create',
  async (payload: PayloadCallBack<IParamCreateBankUser>, { getState }) => {
    try {
      const { messages } = await BankUser.create({ ...payload.param });
      payload.callback(messages);
    } catch (error) {
      console.log(error);
    }
  },
);

export const deleteBankUser = createAsyncThunk(
  'BankUser/delete',
  async (payload: PayloadCallBack<IParamDeleteBankUser>, { getState }) => {
    try {
      const { messages } = await BankUser.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error) {
      console.log(error);
    }
  },
);
const bankUserSlice = createSlice({
  name: 'BankUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankUser/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankUser/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankUser/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default bankUserSlice.reducer;
