import { useAppDispatch } from '@hook/index';
import { authLogout } from '@store/authSlice';
import { Button } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { LogoutOutlined } from '@ant-design/icons';
type ILogout = {
  children: React.ReactNode;
  redirect?: boolean;
  styleEl?: any;
};
export default function Logout(props: ILogout) {
  const { children, redirect, styleEl } = props;
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  /**
   * @description redirect to login when logout
   */
  const redirectLogout = () => {
    navigate('/');
  };

  /**
   * @description handle get api logout
   */
  const handleLogout = () => {
    dispatch(authLogout({ isLogin: false, redirectLogout }));
  };
  return (
    <>
      <div style={{ width: '100%', ...styleEl }} onClick={handleLogout}>
        {children}
      </div>
    </>
  );
}
