import { useAppDispatch, useAppSelector } from '@hook/index';
import { getFooter } from '@store/homeSlice';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { getSetting } from '@store/settingSlice';

export interface IFooterProps {}

export default function Footer(props: IFooterProps) {
  const { data: DataHome } = useAppSelector((state) => state.homeSlice);
  const { data: dataSetting } = useAppSelector((state) => state.settingSlice);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getFooter());
    dispatch(getSetting());
  }, []);
  return (
    <div className="layout-full footer-main-layout">
      <div className="layout-max footer-container">
        <div className="information">
          <div className="item">
            <img src={require('@assets/footer/information/slots777.png')} alt="specialImg1" />
            <ul>
              <li className="title">TRÒ CHƠI ĐA DẠNG</li>
              <li>Trải nghiệm đỉnh cao</li>
            </ul>
          </div>
          <div className="item">
            <img src={require('@assets/footer/information/service.png')} alt="specialImg2" />
            <ul>
              <li className="title">ĐỘI NGŨ CHUYÊN NGHIỆP</li>
              <li>Chăm sóc khách hàng 24/7</li>
            </ul>
          </div>
          <div className="item">
            <img src={require('@assets/footer/information/bank.png')} alt="specialImg3" />
            <ul>
              <li className="title">PHƯƠNG THỨC THANH TOÁN</li>
              <li>Đa dạng và Uy tín</li>
            </ul>
          </div>
          <div className="item">
            <img src={require('@assets/footer/information/speed.png')} alt="specialImg4" />
            <ul>
              <li className="title">THỜI GIAN XỬ LÝ</li>
              <li>Gửi tiền siêu tốc. Rút tiền nhanh chóng</li>
            </ul>
          </div>
        </div>
        <div className="payment">
          <div className="item">
            <div className="title">PHƯƠNG THỨC THANH TOÁN</div>
            <img src={require('@assets/footer/payment/acb.png')} alt="acbImg" />
            <img src={require('@assets/footer/payment/vietin.png')} alt="vietinImg" />
            <img src={require('@assets/footer/payment/vietcom.png')} alt="vietcomImg" />
            <img src={require('@assets/footer/payment/mb.png')} alt="mbImg" />
            <img src={require('@assets/footer/payment/techcom.png')} alt="techcomImg" />

            <img src={require('@assets/footer/payment/sacom.png')} alt="sacomImg" />
            <img src={require('@assets/footer/payment/momo.png')} alt="momoImg" />
            <img src={require('@assets/footer/payment/circlepay.png')} alt="circlepayImg" />
          </div>
        </div>

        <div className="platform">
          <div className="item">
            <button>
              <img alt="logo" src={require('@assets/footer/platform/saba.png')} />
            </button>

            <button>
              <img alt="logo" src={require('@assets/footer/platform/sbo.png')} />
            </button>

            <button>
              <img alt="logo" src={require('@assets/footer/platform/ag.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/ae.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/n2live.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/pp.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/bg.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/yb.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/mg.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/pt.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/pg.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/sg.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/yg.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/cq9.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/jili.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/yl.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/v8.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/kingmaker.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/tcg.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/rt.png')} />
            </button>

            <button>
              <img alt="logo" src={require('@assets/footer/platform/evolution.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/habanero.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/simpleplay.png')} />
            </button>
            <button>
              <img alt="logo" src={require('@assets/footer/platform/sk.png')} />
            </button>
          </div>
        </div>
        <div className="webmap-wrapper">
          <div className="webmap">
            <div className="item">
              <span className="title">Cá cược Thể thao</span>
              <div className="sub-item">
                <button className="">Cá cược Bóng Đá</button>
                <button className="">Cược Bóng Rổ</button>
                <button className="">Cược Bóng Chày</button>
                <button className="">Cược Bóng Bầu Dục</button>
                <button className="">Cá cược Quần Vợt</button>
                <button className="">Cược Hockey</button>
                <button className="">Cược Thể thao điện tử</button>
              </div>
            </div>
            <div className="item">
              <span className="title">Chính Sách</span>
              <div className="sub-item">
                {DataHome.footer?.policy.map((item, index) => {
                  return (
                    <Link key={index} to={`/${item.slug}`}>
                      {item.title}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="item">
              <span className="title">Hỗ Trợ</span>
              <div className="sub-item">
                {DataHome.footer?.suport.map((item, index) => {
                  return (
                    <Link key={index} to={`/${item.slug}`}>
                      {item.title}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="item">
              <span className="title">Khuyến mãi</span>
              <div className="sub-item">
                <Link to={`/khuyen-mai`}>Khuyến mãi</Link>
              </div>
            </div>
            <div className="item">
              <span className="title">Viva88viet.net</span>
              <div className="sub-item">
                <Link to={`https://daily.viva88viet.net`}>Đại lí</Link>
                {DataHome.footer?.agent.map((item, index) => {
                  return (
                    <Link key={index} to={`/${item.slug}`}>
                      {item.title}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="item">
              <span className="title">Liên Hệ</span>
              <div className="sub-item last-item">{dataSetting?.listSetting?.contact ?? ''}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">{dataSetting?.listSetting?.copyright ?? ''}</div>
    </div>
  );
}
