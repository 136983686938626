import * as React from 'react';
import './style.scss';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import UploadMutipleImage from '@components/common/upload/UploadMutipleImage';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getAllBankAdmin } from '@store/bankAdminSlice';
import { convert } from '@utils/convert';
import { deposit } from '@store/transferSlice';
import { ITransferDeposit } from '@interfaces/Transfer';
import showMessage from '@utils/helper';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Link, useLocation, useNavigate } from 'react-router-dom';
export interface IDepositPCProps {}

export default function DepositPC(props: IDepositPCProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { data } = useAppSelector((state) => state.authSlice);
  const { data: DataHome } = useAppSelector((state) => state.homeSlice);
  const { data: DataBank } = useAppSelector((state) => state.bankAdminSlice);
  const { data: DataGame } = useAppSelector((state) => state.gameSlice);
  const { data: DateEvent } = useAppSelector((state) => state.eventSlice);
  const [methodKey, setMethodKey] = React.useState<number>(0);
  const [bankKey, setBankKey] = React.useState<number>(0);
  const MySwal = withReactContent(Swal);
  const [dataSubmit, setDataSubmit] = React.useState<Partial<ITransferDeposit>>({
    bank_id: DataBank.listBank[0]?.id || 1,
    transfer_type: 0,
  });

  const handleResetFile = () => {
    form.resetFields();
  };

  const listIconMethod = [
    { key: 0, el: <i className="main-icon fa-solid fa-building-columns"></i> },
    {
      key: 1,
      el: <i className="main-icon  fa-solid fa-mobile-screen-button"></i>,
    },
    {
      key: 2,
      el: <i className="main-icon  fa-solid fa-wifi"></i>,
    },
    { key: 3, el: <i className="main-icon  fa-solid fa-wallet"></i> },
    { key: 4, el: <i className="main-icon  fa-regular fa-credit-card"></i> },
    { key: 5, el: <i className="main-icon  fa-solid fa-qrcode"></i> },
    { key: 6, el: <i className="main-icon  fa-solid fa-repeat"></i> },
  ];

  const typeEvent = location.state
    ? DateEvent.listEvent.filter((item) => item.apply_type === location.state?.typeEvent)
    : DateEvent.listEvent.filter((item) => item.apply_type === 3);

  const handleChangePage = () => {
    navigate('/thanh-vien/lich-su-nap-tien');
  };

  const handleSubmit = (event: ITransferDeposit) => {
    setDataSubmit({ ...dataSubmit, ...event });
    const pointValue = parseFloat(parseFloat(event.point.toString().replace(/,/g, '')).toFixed(2));
    dispatch(
      deposit({
        param: {
          ...dataSubmit,
          ...event,
          bank_id: DataBank.listBank[bankKey]?.id,
          point: pointValue,
        },
        callback: (messages) => {
          MySwal.fire({
            title: messages,
            customClass: { confirmButton: 'btn-confirm' },
            confirmButtonText: <span onClick={handleChangePage}>Lịch sử nạp tiền</span>,
          });
          handleResetFile();
        },
        error: (error) => {
          MySwal.fire({
            title: `Có lỗi!`,
            text: error,
            icon: 'error',
            confirmButtonText: 'OK',
            customClass: { confirmButton: 'btn-confirm' },
          });
        },
      }),
    );
  };

  const changeMethod = (event, index: number) => {
    event.preventDefault();
    setMethodKey(index);
    dispatch(getAllBankAdmin({ card_type: index + 1 }));
  };

  const changeBank = (event, index: number) => {
    event.preventDefault();
    setBankKey(index);
  };

  const handleChangeImg = (imgList: Object[]) => {
    setDataSubmit({ ...(dataSubmit as ITransferDeposit), images: imgList });
  };

  const handleChange = (event) => {
    if (event.money) {
      const money = event.money.toString().replace(/,/g, '');
      form.setFieldsValue({
        ...event,
        money: convert.moneyInputs(money),
        point: convert.numWithCommas(money.replace(/\./g, '').replace(',', '') / Number(data?.point_price)),
      });
    } else if (event.point) {
      const point = event.point.toString().replace(/,/g, '');
      form.setFieldsValue({
        ...event,
        money: convert.moneyInputs(point * Number(data?.point_price)),
        point: convert.numWithCommas(point),
      });
    }
  };

  return (
    <div className="deposit-member">
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleSubmit}
        onValuesChange={handleChange}
        autoComplete="off"
      >
        <div className="rightModule">
          <div className="infoModule">
            <div className="inside-title icon icon-icon32px_deposit">
              <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <i style={{ fontSize: '30px' }} className="fa-solid fa-money-check-dollar"></i>Nạp tiền
              </span>
              <div>Bạn có thể chọn mục nạp tiền phù hợp nhất từ những lựa chọn bên dưới:</div>
            </div>
            <div className="subModuleListColumn">
              <div className="subModuleTitle">
                <span>
                  <i className="txt-blue">*</i>
                </span>
                Phương thức nạp tiền
              </div>
              <div className="subModule">
                <div>
                  {Object.values(DataBank.method || {}).map((item, index) => (
                    <button
                      key={item}
                      className={(methodKey === index && 'active') || ''}
                      onClick={(e) => changeMethod(e, index)}
                    >
                      <div className="icon">{listIconMethod[index].el}</div>
                      <div className="bank">
                        <div>{item}</div>
                        {/* <div>Thời gian thực hiện ：5 phút</div> */}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
              <div className="subModuleTitle">
                <span>
                  <i className="txt-blue">*</i>Chọn ngân hàng
                </span>
              </div>
              <div className="subModule select-bank">
                <div>
                  {DataBank.listBank.map((item, index) => (
                    <button
                      key={item.id}
                      className={(bankKey === index && 'active') || ''}
                      onClick={(e) => changeBank(e, index)}
                    >
                      <div>
                        <img src={item?.bank.image || item?.bank.thumbail} alt={item?.bank_type} />
                      </div>
                      <div className="bank">
                        <div>{item.bank_type}</div>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
              <div className="subModuleTitle">
                <span>
                  <i className="txt-blue">*</i>Tài khoản ngân hàng
                </span>
              </div>
              <div className="subModule bankQR">
                <div className="bankAccount">
                  Vui lòng chuyển tiền đến tài khoản sau.
                  <div className="smallList">
                    <ul>
                      <li>Tên ngân hàng</li>
                      <li>{DataBank.listBank[bankKey]?.bank_type}</li>
                    </ul>
                    <ul>
                      <li>Tên tài khoản</li>
                      <li>{DataBank.listBank[bankKey]?.owner_name}</li>
                    </ul>
                    <ul>
                      <li>Số tài khoản</li>
                      <li>{DataBank.listBank[bankKey]?.card_no}</li>
                    </ul>
                  </div>
                </div>
                <img
                  className="img-QR"
                  src={DataBank.listBank[bankKey]?.qrcode}
                  alt={DataBank.listBank[bankKey]?.owner_name}
                />
              </div>
              <div className="subModuleTitle">
                <span>
                  <i className="txt-blue">*</i>Trò chơi (
                  <span className="red-color">Nội dung chuyển khoản: {data?.username}</span>)
                </span>
              </div>
              <div className="subModule">
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="" name="game_id" rules={[{ required: true, message: 'Vui lòng chọn trò chơi' }]}>
                    <Select
                      allowClear
                      showSearch
                      placeholder="Chọn trò chơi"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={DataGame.listGame?.map((item) => ({ value: item.id, label: item.title }))}
                    />
                  </Form.Item>
                </Col>
              </div>
              <div className="subModuleTitle">
                <span>
                  <i className="txt-blue">*</i>
                  {`Số tiền (Đơn vị là 1 = ${convert.moneyInput(data?.point_price)} VNĐ). `}
                  {`Vui lòng nhập số điểm hoặc số tiền là số chẵn`}
                </span>
              </div>
              <div className="subModule">
                <Row gutter={10} className="row-form" justify="start">
                  <Col span={10}>
                    <Form.Item
                      label=""
                      name="point"
                      rules={[
                        { required: true, message: 'Vui lòng nhập số điểm' },
                        {
                          message: 'Số điểm phải lớn hơn hoặc bằng 1',
                          validator: (_, value) => {
                            if (Number(parseInt((value === '' ? 0 : value).replace(/\./g, '').replace(',', ''))) >= 1) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject('Số điểm phải lớn hơn hoặc bằng 1');
                            }
                          },
                        },
                      ]}
                    >
                      <Input placeholder="Mua điểm" />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      label=""
                      name="money"
                      rules={[
                        { required: true, message: 'Vui lòng nhập số tiền' },
                        {
                          message: 'Số tiền phải lớn hơn hoặc bằng ' + convert.moneyInput(data?.point_price),
                          validator: (_, value) => {
                            if (
                              Number(parseInt((value === '' ? 0 : value).toString().replace(/,/g, ''))) >=
                              Number(data?.point_price)
                            ) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                `Số tiền phải lớn hơn hoặc bằng ${convert.moneyInput(data?.point_price)}`,
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input placeholder="Số tiền" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="subModuleTitle">
                <span>
                  <i className="txt-blue">*</i>Biên lai ngân hàng
                </span>
              </div>
              <div className="subModule">
                <Col xs={{ span: 24 }} sm={{ span: 20 }}>
                  <Form.Item colon={false} name={'images-upload'}>
                    <UploadMutipleImage handleChangeImg={handleChangeImg} />
                    <span className="note">Tối đa 6 hóa đơn</span>
                  </Form.Item>
                </Col>
              </div>
              <div className="subModuleTitle">
                <span>
                  <i className="txt-blue" />
                  Chọn khuyến mãi
                </span>
              </div>
              <div className="subModule">
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="" name="event_id">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Chọn sự kiện"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={(typeEvent || DateEvent.listEvent).map((item) => ({
                        value: item.id,
                        label: item.title,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </div>
              <div className="btnGroup">
                <Button htmlType="submit" className="darkGray">
                  Nạp tiền
                </Button>
              </div>
            </div>
          </div>

          <a className="icon icon-books" href="/vn/depositHistory" />
        </div>
      </Form>
    </div>
  );
}
